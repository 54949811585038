import { ModalContentWrapper } from "components/ModalContentWrapper";
import { FC } from "react";
import { Assignment } from "types/assignment.type";
import {
  AssignmentInfoCustomer,
  AssignmentInfoNotes,
  AssignmentInfoOperatorInfo,
  AssignmentInfoReferenceNumber,
  AssignmentInfoReportedHours,
  AssignmentInfoSeparator,
  AssignmentInfoTomningInfo,
  AssignmentInfoType,
} from "pages/app/aktuella-uppdrag/AssignmentInfoModal";
interface Props {
  assignment: Assignment;
}
export const TidsrapporteringModal: FC<Props> = ({ assignment }) => {
  if (!assignment.tomning) {
    return null;
  }
  return (
    <ModalContentWrapper>
      <dl className="grid grid-cols-1 gap-x-4 gap-y-10 sm:grid-cols-2 p-4 sm:p-0 lg:grid-cols-2">
        <AssignmentInfoType assignment={assignment} />
        <AssignmentInfoOperatorInfo assignment={assignment} />
        <AssignmentInfoCustomer assignment={assignment} />
        <AssignmentInfoSeparator assignment={assignment} />
        <AssignmentInfoReferenceNumber assignment={assignment} />
        <AssignmentInfoNotes assignment={assignment} />
        <AssignmentInfoTomningInfo
          assignment={assignment}
          includeReportedHours={false}
        />
        <AssignmentInfoReportedHours assignment={assignment} highlight />
      </dl>
    </ModalContentWrapper>
  );
};
